.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #000000;
  color: #ffffff;
  min-height: 100vh;
}

.closure-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
}

.closure-form label,
.email-preview label {
  margin-bottom: 15px;
  font-size: 1.1em;
}

.closure-form select,
.closure-form textarea,
.email-preview input,
.email-preview textarea {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border-radius: 5px;
  border: 1px solid #fee600;
  background-color: #000000;
  color: #ffffff;
}

.closure-form textarea,
.email-preview textarea {
  height: 100px;
  resize: none;
}

.submit-button,
.send-button {
  background-color: #fee600;
  color: #000000;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 1.1em;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-align: center;
}

.submit-button:hover,
.send-button:hover {
  background-color: #ffd700;
}

.email-preview {
  margin-top: 20px;
  width: 100%;
  max-width: 400px;
}

.email-preview h2 {
  margin-bottom: 15px;
  font-size: 1.2em;
  color: #fee600;
}

.mailto-link {
  margin-top: 20px;
}

.mailto-link a {
  color: #fee600;
  text-decoration: none;
  font-size: 1.2em;
}

.mailto-link a:hover {
  text-decoration: underline;
}

.instructions-link {
  margin-top: 20px;
}

.instructions-toggle {
  background: none;
  border: none;
  color: #fee600;
  cursor: pointer;
  font-size: 1.2em;
  text-decoration: underline;
}

.instructions-toggle:hover {
  text-decoration: none;
}

.default-mail-app-instructions {
  margin-top: 10px;
  width: 100%;
  max-width: 400px;
}

.default-mail-app-instructions h2 {
  margin-bottom: 15px;
  font-size: 1.2em;
  color: #fee600;
}

.default-mail-app-instructions h3 {
  margin-bottom: 10px;
  font-size: 1.1em;
  color: #fee600;
}

.default-mail-app-instructions p {
  margin-bottom: 5px;
  font-size: 1em;
  color: #ffffff;
}
